import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  FormHelperText,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LoginPng from "../../../../src/images/enter-otp.png";
import Logo from "../../../../src/images/credmudra_logo_new.webp";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import OtpInput from "react-otp-input";
import apiEndPointsConfig from "../../../config/apiEndPointConfig";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../services/apiCallServices";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  setBanks,
  setBusinessOwned,
  setBusinessType,
  setCompanyAddress,
  setCompanyType,
  setFormData,
  setGenderType,
  setIndustryType,
  setLoanReason,
  setPersonalAddress,
  setPincodeError,
  setQualifications,
  setResidenceType,
  setUserExist,
} from "../../../store/reducers";
import { verifyUserSchema } from "../../../utils/validateSchema";
import { getInitialVerifyUserValues } from "../../../utils/initialValues";
import Loader from "../../../components/Loader/page";
import { isAndroid, isIPhone } from "../../../utils/deviceDetection";
import { mdiOpacity } from "@mdi/js";
import Bowser from "bowser";
import Cookies from "js-cookie";
const EnterOtp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width:900px)");
  const isXSmallScreen = useMediaQuery("(max-width: 600px)");
  const logoWidth = isXSmallScreen ? "180px" : "245px";
  const [placeholders, setPlaceholders] = useState(new Array(6).fill("-"));
  const [SendOtpApi, sendOptData] = useApiCallMutation();
  const [timer, setTimer] = useState(60);
  //Api Initialization
  const [VerifyOtpApi, validateOptData] = useApiCallMutation();
  //data from redux
  const formData = useSelector((state) => state?.form?.formData);

  const [utmData] = useState(JSON.parse(localStorage.getItem("utms")));
  useEffect(() => {
    const contactNumber = atob(contact);
    sessionStorage.setItem("contactNumber", contactNumber);
    const anonymousId = sessionStorage.getItem("anonymousId");
    // if (contactNumber && contactNumber?.length == 10) {
    //   const data = {
    //     contactNo: contactNumber,
    //     resend: false,
    //     anonymousId: anonymousId || "",
    //   };
    //   SendOtpApi({
    //     endPoint: apiEndPointsConfig.SendOtp,
    //     method: "POST",
    //     data: {
    //       data,
    //     },
    //   });
    // }
  }, []);
  const handleFocus = (index) => {
    setPlaceholders((prev) => {
      const newPlaceholders = [...prev];
      newPlaceholders[index] = "";
      return newPlaceholders;
    });
  };

  const handleBlur = (index) => {
    if (!formik.values.verifyOtp[index]) {
      setPlaceholders((prev) => {
        const newPlaceholders = [...prev];
        newPlaceholders[index] = "-";
        return newPlaceholders;
      });
    }
  };
  const CircleIcon = {
    color: "#4769D0",
    fontSize: "15px",
    marginRight: "8px",
  };
  const ListStyling = {
    padding: "0px",
    fontSize: { xs: "16px", sm: "12px" },
    margin: { xs: "0px 0px 5px" },
    fontFamily: "Inter,sans-serif",
    color: "#000",
  };
  const getInputWidth = () => {
    if (isXSmallScreen) {
      return isAndroid() ? "35px" : isIPhone() ? "15px" : "35px";
    } else {
      return "55px";
    }
  };
  const inputWidth = getInputWidth();

  //querygetparams
  const getQueryParam = (name) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  };
  // const urlParams = new URLSearchParams(window.location.search);
  const encodedAnonymousId = getQueryParam("id");
  const contact = getQueryParam("contact");
  const currentPath = window.location.pathname;
  const extraDetails = async () => {
    const browser = Bowser.getParser(navigator.userAgent);
    let position = null;
    let ipAddress = "";
    try {
      const ipResponse = await fetch("https://api64.ipify.org?format=json");
      if (!ipResponse.ok) {
        throw new Error("Failed to fetch IP address");
      }
      const ipData = await ipResponse.json();

      ipAddress = ipData.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
    try {
      position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    } catch (error) {}
    dispatch(
      setFormData({
        extras: {
          ...formData.extras,
          browser:
            browser?.parsedResult.browser.name +
            " " +
            browser?.parsedResult.browser.version,
          operatingSystem: browser?.parsedResult.os.name,
          ipAddress: ipAddress,
          userAgent: "",
          location: "",
          timestamp: position?.timestamp,
        },
      })
    );
    sessionStorage.setItem(
      "extras",
      JSON.stringify({
        ...formData.extras,
        browser:
          browser?.parsedResult.browser.name +
          " " +
          browser?.parsedResult.browser.version,
        operatingSystem: browser?.parsedResult.os.name,
        ipAddress: ipAddress,
        userAgent: "",
        location: "",
        timestamp: position?.timestamp,
      })
    );
  };
  const set_utm = () => {
    const utm_id = getQueryParam("utm_id");
    const utm_url = getQueryParam("utm_url");
    const utm_source = getQueryParam("utm_source");
    const utm_medium = getQueryParam("utm_medium");
    const utm_campaign = getQueryParam("utm_campaign");
    const utm_term = getQueryParam("utm_term");
    const utm_content = getQueryParam("utm_content");

    //setting utms in cookies
    const utmsData = localStorage.getItem("utms");
    if (!utmsData) {
      localStorage.setItem(
        "utms",
        JSON.stringify({
          id: utm_id,
          url: utm_url,
          source: utm_source,
          medium: utm_medium,
          campaign: utm_campaign,
          term: utm_term,
          content: utm_content,
          clickId: "",
        }),
        { expires: 30, secure: true }
      );
    } else if (utmsData && utm_source !== null) {
      localStorage.setItem(
        "utms",
        JSON.stringify({
          id: utm_id,
          url: utm_url,
          source: utm_source,
          medium: utm_medium,
          campaign: utm_campaign,
          term: utm_term,
          content: utm_content,
          clickId: "",
        }),
        { expires: 30, secure: true }
      );
    }
    const postUtmsData = JSON.parse(localStorage.getItem("utms"));
    dispatch(
      setFormData({
        utm: {
          id: postUtmsData.id || utm_id,
          url: postUtmsData.url || utm_url,
          source: postUtmsData.source || utm_source,
          medium: postUtmsData.medium || utm_medium,
          campaign: postUtmsData.campaign || utm_campaign,
          term: postUtmsData.term || utm_term,
          content: postUtmsData.content || utm_content,
          clickId: "",
        },
      })
    );
  };
  useEffect(() => {
    extraDetails();
    set_utm();
  }, []);
  //annonymous Api Call
  const [annonymousApi, annonymousApiData] = useApiCallMutation();
  const getAnonymousUserId = async () => {
    const postUtmsData = JSON.parse(localStorage.getItem("utms"));
    const utm_id = getQueryParam("utm_id");
    const utm_url = getQueryParam("utm_url");
    const utm_source = getQueryParam("utm_source");
    const utm_medium = getQueryParam("utm_medium");
    const utm_campaign = getQueryParam("utm_campaign");
    const utm_term = getQueryParam("utm_term");
    const utm_content = getQueryParam("utm_content");

    const browser = Bowser.getParser(navigator.userAgent);
    var position = "";
    var ipAddress = "";
    try {
      const ipResponse = await fetch("https://api64.ipify.org?format=json");
      if (!ipResponse.ok) {
        throw new Error("Failed to fetch IP address");
      }
      const ipData = await ipResponse.json();

      ipAddress = ipData.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
    navigator.geolocation.getCurrentPosition(
      async (pos) => {
        position = pos;
      },
      (err) => {
        console.log(`Error getting location: ${err.message}`);
      }
    );
    const data = {
      utm: {
        id: postUtmsData.id || utm_id,
        url: postUtmsData.url || utm_url,
        source: postUtmsData.source || utm_source,
        medium: postUtmsData.medium || utm_medium,
        campaign: postUtmsData.campaign || utm_campaign,
        term: postUtmsData.term || utm_term,
        content: postUtmsData.content || utm_content,
        clickId: "",
      },
      extra: {
        browser:
          browser?.parsedResult.browser.name +
          " " +
          browser?.parsedResult.browser.version,
        operatingSystem: browser?.parsedResult.os.name,
        ipAddress: ipAddress,
        timestamp: "",
        userAgent: "",
        location: position?.timestamp,
      },
      path: currentPath,
    };
    annonymousApi({
      endPoint: apiEndPointsConfig?.anonymousUser,
      method: "POST",
      data: { data },
    });
  };
  useEffect(() => {
    if (annonymousApiData?.isSuccess) {
      sessionStorage.setItem(
        "anonymousId",
        annonymousApiData?.data?.data?.anonymousId
      );
      if (annonymousApiData?.data?.data?.anonymousId) {
        const contactNumber = sessionStorage.getItem("contactNumber") || "";
        // sessionStorage.setItem("contactNumber", contactNumber);
        const anonymousId = sessionStorage.getItem("anonymousId");
        if (contactNumber && contactNumber?.length == 10) {
          const data = {
            contactNo: contactNumber,
            resend: false,
            anonymousId: anonymousId || "",
          };
          SendOtpApi({
            endPoint: apiEndPointsConfig.SendOtp,
            method: "POST",
            data: {
              data,
            },
          });
        }
      }
    }
  }, [annonymousApiData]);
  useEffect(() => {
    const anonymousId = sessionStorage.getItem("anonymousId");
    if (!anonymousId) {
      getAnonymousUserId();
    } else {
      const contactNumber = sessionStorage.getItem("contactNumber") || "";
      // sessionStorage.setItem("contactNumber", contactNumber);
      const anonymousId = sessionStorage.getItem("anonymousId");
      if (contactNumber && contactNumber?.length == 10) {
        const data = {
          contactNo: contactNumber,
          resend: false,
          anonymousId: anonymousId || "",
        };
        SendOtpApi({
          endPoint: apiEndPointsConfig.SendOtp,
          method: "POST",
          data: {
            data,
          },
        });
      }
    }
  }, []);

  //handleSubmit
  const handleSubmit = async (verifyOtp) => {
    const contactData = sessionStorage.getItem("contactNumber") || "";
    const data = {
      contactNo: contactData?.length === 10 ? contactData : formData?.contactNo,
      otp: verifyOtp,
    };
    VerifyOtpApi({
      endPoint: apiEndPointsConfig.ValidateOtp,
      method: "POST",
      data: {
        data,
      },
    });
  };
  useEffect(() => {
    const isPublicLeadId = sessionStorage.getItem("publicLeadId");
    if (
      validateOptData?.isSuccess &&
      validateOptData?.data?.status?.code === 200
    ) {
      const responseData = validateOptData?.data?.data;
      sessionStorage.setItem("accessToken", responseData.token.accessToken);
      sessionStorage.setItem("refreshToken", responseData.token.refreshToken);
      sessionStorage.setItem("leadId", responseData.leadId);
      const form = {
        ...formData,
        leadId: responseData?.leadId,
      };
      dispatch(setFormData(form));
      if (isPublicLeadId) {
        const leadId = sessionStorage.getItem("publicLeadId");
        sessionStorage.removeItem("publicLeadId");
        navigate("/profiling/dashboard/personalLoan", {
          state: { from: "otp", leadId: leadId },
        });
      } else {
        navigate("/");
      }
    } else if (validateOptData?.data?.status?.code === 400) {
      formik.setFieldError("verifyOtp", "Invalid OTP");
    }
  }, [validateOptData]);

  const handleChange = async (val) => {
    const anonymousId = sessionStorage.getItem("anonymousId") || "";
    let currentTimer = 60;
    const timerInterval = setInterval(() => {
      currentTimer -= 1;
      setTimer(currentTimer);
      if (currentTimer <= 0) {
        clearInterval(timerInterval);
      }
    }, 1000);
    if (val === true) {
      const data = {
        contactNo: formData.contactNo,
        resend: true,
        anonymousId: anonymousId || "",
      };
      SendOtpApi({
        endPoint: apiEndPointsConfig.SendOtp,
        method: "POST",
        data: {
          data,
        },
      });
    }
  };
  useEffect(() => {
    handleChange();
  }, []);

  useEffect(() => {
    const user = sessionStorage.getItem("accessToken");
    const number = sessionStorage.getItem("number");
    if (user) {
      navigate(-1);
    } else if (!number && !contact) {
      navigate("/login");
    }
  }, []);

  const formik = useFormik({
    initialValues: getInitialVerifyUserValues(),
    validationSchema: verifyUserSchema,
    onSubmit: (values) => {
      handleSubmit(values.verifyOtp);
    },
  });
  useEffect(() => {
    const handleUnload = (event) => {
      const confirmationMessage = "Are you sure you want to leave? ";
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    const handleLoad = () => {
      // const annonymousID = sessionStorage.getItem('anonymousId');
      // sessionStorage.clear();
      // sessionStorage.setItem('anonymousId',annonymousID)
      // navigate("/login");
    };

    window.addEventListener("beforeunload", handleUnload);
    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <>
      {validateOptData?.isLoading && <Loader />}
      <Box sx={{ width: "100%" }}>
        <Grid container sx={{ mb: { xs: 4, md: 0 } }}>
          <Grid
            item
            sx={{
              background:
                "linear-gradient(4deg, #6582DB -39.43%, #3156C7 45.69%)",
              display: "grid",

              height: { md: "100vh", xs: "230px", lg: "100vh" },
            }}
            xs={12}
            sm={12}
            md={6}
          >
            <Box
              sx={{
                position: "absolute",
                mt: { xs: 1, sm: 3, md: 5 },
                ml: { xs: 2, sm: 3, md: 5 },
                width: { xs: "30%", sm: "20%", md: "22%", lg: "12%" },
              }}
            >
              <img width={"100%"} alt="logo" src={Logo} />
            </Box>
            <Box
              sx={{
                backgroundImage: `url( ${process.env.PUBLIC_URL}/assets/commonImages/sidebackgroundImg.png)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: { xs: "25%", sm: "20%", md: "30%" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: { md: "100vh", xs: "240px", lg: "100vh" },
              }}
            >
              <Box
                sx={{
                  height: { xs: "75%", md: "40%", lg: "50%" },
                }}
              >
                <img height={"100%"} src={LoginPng} alt="logo" />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            // sx={{
            //   display: "flex",
            //   // alignItems: "center",
            //   // justifyContent: "center",
            // }}
          >
            <Container
              maxWidth={"md"}
              sx={{
                height: "100vh",
                width: {
                  xs: "100%",
                  sm: "70%",
                  md: "450px",
                },
                paddingLeft: { xs: "10px", sm: "0px" },
                ...(isDesktop && {
                  overflowY: "scroll",
                  "-ms-overflow-style": "none",
                  scrollbarWidth: "none",
                }),
              }}
            >
              <Box
                sx={{
                  // width: {
                  //   xs: "300px",
                  //   sm: "450px",
                  // },
                  paddingLeft: { xs: "10px", sm: "0px" },
                  paddingTop: { xs: "10px", md: "15%", lg: "15%" },
                }}
              >
                <Box mt={{ xs: 3, sm: 5 }}>
                  <Typography variant="h5" sx={{ fontWeight: 700 }}>
                    OTP Verification{" "}
                  </Typography>
                  <Typography
                    mt={{ xs: 1, sm: 2 }}
                    sx={{ fontSize: { xs: "13px", sm: "16px" } }}
                  >
                    OTP has been sent to your mobile number. Please verify
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      sx={{
                        fontSize: "0.8rem",
                      }}
                      onClick={() => {
                        sessionStorage.removeItem("number");
                        navigate("/login");
                      }}
                    >
                      Change Number
                    </Button>
                  </Box>
                </Box>
                <form onSubmit={formik.handleSubmit}>
                  <Box
                    sx={{
                      width: { xs: "300px", sm: "100%", md: "100%" },
                      mt: { xs: 2, sm: 3 },
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 600, lineHeight: "28.8px", mb: 1 }}
                    >
                      Enter OTP
                    </Typography>

                    <OtpInput
                      value={formik.values.verifyOtp}
                      inputType="tel"
                      onChange={(otpValue) => {
                        if (/^\d*$/.test(otpValue) && otpValue.length <= 6) {
                          formik.setFieldValue("verifyOtp", otpValue);
                        }
                      }}
                      numInputs={6}
                      renderInput={(props, index) => (
                        <input
                          {...props}
                          placeholder={placeholders[index]}
                          style={{
                            width: inputWidth,
                            height: inputWidth,
                            fontSize: "20px",
                            margin: "0 5px",
                            textAlign: "center",
                            border: formik.errors.verifyOtp
                              ? "1px solid red"
                              : "1px solid #ccc",
                            borderRadius: "5px",
                            "@media (max-width: 600px)": {
                              width: "30px !important",
                              height: "30px !important",
                              fontSize: "16px !important",
                            },
                          }}
                          onFocus={() => handleFocus(index)}
                          onBlur={() => handleBlur(index)}
                        />
                      )}
                    />

                    {formik.errors.verifyOtp && (
                      <FormHelperText
                        sx={{
                          margin: 0,
                          color: "red",
                          fontSize: "14px",
                          ml: 1,
                        }}
                      >
                        {formik.errors.verifyOtp}
                      </FormHelperText>
                    )}
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        mt: { xs: 2, sm: 2 },
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            fontSize: { xs: "13px", sm: "16px" },
                            whiteSpace: "nowrap",
                            display: timer > 0 ? "block" : "none",
                          }}
                        >
                          Resend OTP in{" "}
                          <span style={{ color: "#008000" }}>00:{timer}</span>
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <Button
                          sx={{
                            textTransform: "none",
                            color: timer > 0 ? "#888" : "#3B5BBA",
                            fontSize: "0.8rem",
                            cursor: timer > 0 ? "not-allowed" : "pointer",
                            pointerEvents: timer > 0 ? "none" : "auto",
                          }}
                          disabled={timer > 0}
                          onClick={() => {
                            if (timer <= 0) {
                              handleChange(true);
                            }
                          }}
                        >
                          Resend OTP
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: "#3B5BBA",
                      width: "100%",
                      height: "48px",
                      mt: { xs: 3, sm: 8 },
                      fontSize: { xs: "13px", sm: "14px" },
                    }}
                  >
                    Verify
                  </Button>
                </form>
              </Box>
              <Box
                sx={{
                  paddingTop: "60px ",
                }}
              >
                <Container sx={{ display: "block", paddingRight: "0px" }}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "700",
                        marginBottom: "15px",
                        marginTop: "20px",
                        color: "#243771",
                      }}
                    >
                      {utmData.campaign === "BL"
                        ? "Features and Benefits of Business Loans:"
                        : "Features and Benefits of Personal Loans:"}
                    </Typography>
                    {utmData.campaign === "BL" ? (
                      <List>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            The loan amount is credited to your account swiftly
                            once your application and documents are successfully
                            verified. With Credmudra, you can even receive the
                            funds on the same day.
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Business loans are unsecured, so you don’t need to
                            provide any collateral to get the loan approved.
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Only a few essential documents are required to
                            process your loan application.
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Use a business loan EMI calculator to estimate your
                            monthly payments and select an EMI plan that aligns
                            with your financial capacity.
                          </Typography>
                        </ListItem>

                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            You can use the loan amount for any business-related
                            expense without any specific limitations from the
                            lender.
                          </Typography>
                        </ListItem>
                      </List>
                    ) : (
                      <List>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Personal loans come with no restrictions on how the
                            funds are used.
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            The loan amount can go up to Rs. 40 lakh, or even
                            higher based on the discretion of the lenders.
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Repayment tenure ranges from a minimum of 3 months
                            to a maximum of 5 years, banks/NBFCs offering longer
                            durations.
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Minimal documentation is required to apply for a
                            personal loan.
                          </Typography>
                        </ListItem>

                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Quick disbursals ensure fast access to the funds.{" "}
                          </Typography>
                        </ListItem>

                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Individuals with excellent credit profiles may
                            qualify for pre-approved or pre-qualified personal
                            loans, which come with instant disbursal.
                          </Typography>
                        </ListItem>
                      </List>
                    )}
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "700",
                        marginBottom: "15px",
                        color: "#243771",
                        marginTop: "20px",
                      }}
                    >
                      {utmData.campaign === "BL"
                        ? " Eligibility Criteria for Business Loans:"
                        : " Eligibility Criteria for Personal Loans:"}
                    </Typography>
                    {utmData.campaign === "BL" ? (
                      <List>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Age: 18 - 60 years{" "}
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Business owners, individuals, entrepreneurs, MSMEs,
                            startups and self-employed professionals, such as
                            CS, CAs, architects and doctors
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Public and private limited companies, partnership
                            firms, sole proprietorships, LLPs
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Large enterprises dealing only in trading, services
                            and manufacturing sectors
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Co-operative societies, NGOs and trusts
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Age: 21 to 65 years old
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Employment type: Self-employed
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Business tenure: At least 1 year or more
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Minimum yearly turnover: Vary across lenders
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Credit score: 750 or more
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Note: The above-mentioned details can vary from
                            lender to lender depending on their terms and
                            conditions.
                          </Typography>
                        </ListItem>
                      </List>
                    ) : (
                      <List>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Age: 18 - 60 years{" "}
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Income: Minimum Rs 15,000/month for salaried
                            applicants
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Credit Score: Preferably 750 and above as having
                            higher credit scores increase the chances of your
                            loan approval at lower interest rates
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Eligible only for individuals receiving their salary
                            in a bank account
                          </Typography>
                        </ListItem>
                      </List>
                    )}
                  </Box>
                  {utmData.campaign === "BL" ? (
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "700",
                          marginBottom: "15px",
                          marginTop: "20px",
                          color: "#243771",
                        }}
                      >
                        APR (Annual Percentage Rate) on Business Loans
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Credmudra is not a direct lender but connects businesses
                        with various lending partners. These partners offer
                        business loans with varying APRs, typically ranging from
                        11.29% to 35%. The actual rates and terms depend on the
                        borrower's financial profile and loan requirements.
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Loan terms can range from a few weeks to a maximum of 60
                        months (5 years). Borrowers must thoroughly review the
                        loan agreement provided by the lender before accepting
                        any offers. The agreement includes the final APR, fees,
                        and specific terms and conditions, ensuring borrowers
                        fully understand their financial obligations.
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Assume you have availed a business loan of ₹10,00,000 at
                        14.00% p.a. with a repayment tenure of 5 years. The
                        processing fee charged for this loan is 2% of the loan
                        amount, i.e., ₹20,000. Based on this, the APR for your
                        business loan will be 14.75%, and the EMI will be
                        ₹23,268.
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Principal Amount: ₹10,00,000
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Interest Charges (@14%): ₹3,96,080
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Loan Processing Fees (@2%): ₹20,000
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        EMI per month: ₹23,268
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Total Amount Paid after 5 Years: ₹14,16,080
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Note: The interest rate and processing fees may vary
                        depending on the lender's policies and the loan product.
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        <b>Disclaimer: </b> Credmudra functions as a loan
                        aggregator and provides services on behalf of its
                        partners, duly authorized for the purpose.
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        The registered address is B No.08 Nirupam, PH-II Nirupam
                        state, Ahmedpur kalan Hujur, Bhopal, Madhya Pradesh
                        462026.
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "700",
                          marginBottom: "15px",
                          marginTop: "20px",
                          color: "#243771",
                        }}
                      >
                        {utmData.campaign === "BL"
                          ? "APR (Annual Percentage Rate) on Business Loans"
                          : "APR (Annual Percentage Rate) on Personal Loans"}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Credmudra isn't a lender itself but connects borrowers
                        with different lending partners. These partners offer
                        personal loans with varying APRs, typically ranging from
                        11.29% to 35%. The actual rates may depend on the
                        borrower's financial situation and loan requirements.
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Loan terms usually range from a few weeks to a maximum
                        of 60 months (5 years), but they can vary between
                        lenders. It's important for borrowers to carefully
                        review the loan agreement provided by the lender before
                        accepting any offers. This agreement includes the final
                        APR, fees, and specific terms and conditions, ensuring
                        borrowers understand their financial obligations.
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Assume you have availed a personal loan of ₹5,00,000
                        lakhs @ 11.50% p.a. with a repayment tenure of 5 years.
                        The processing fee charged for this loan is 1.5% of the
                        loan amount, i.e. ₹7,500. Therefore, the APR for your
                        personal loan will be 12.16%. The EMI will be ₹10,996.
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Principal Amount: ₹5,00,000{" "}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Interest Charges (@11.5%): ₹1,59,778{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Loan Processing Fees (@1.5%): ₹7,500{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        EMI per month: ₹ 10,996{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Total Amount paid after 5 Years: ₹ 6,67,278{" "}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        *Interest rate and processing fees varies as per your
                        product depending on the lender's policy.
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        <b>Disclaimer: </b> Credmudra functions as a loan
                        aggregator and provides services on behalf of its
                        partners, duly authorized for the purpose.
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        The registered address is B No.08 Nirupam, PH-II Nirupam
                        state, Ahmedpur kalan Hujur, Bhopal, Madhya Pradesh
                        462026.
                      </Typography>
                    </Box>
                  )}
                </Container>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EnterOtp;
